define("discourse/plugins/chat/discourse/components/chat-drawer/header/channel-title", ["exports", "@glimmer/component", "@ember/modifier", "@ember/routing", "@ember/service", "discourse/plugins/chat/discourse/components/channel-title", "@ember/template-factory", "@ember/component"], function (_exports, _component, _modifier, _routing, _service, _channelTitle, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatDrawerChannelHeaderTitle = _exports.default = (_class = (_class2 = class ChatDrawerChannelHeaderTitle extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chatStateManager", _descriptor, this);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if @channel}}
        {{#if this.chatStateManager.isDrawerExpanded}}
          <LinkTo
            @route={{if
              @channel.isDirectMessageChannel
              "chat.channel.info.settings"
              "chat.channel.info.members"
            }}
            @models={{@channel.routeModels}}
            class="chat-drawer-header__title"
          >
            <div class="chat-drawer-header__top-line">
              <ChannelTitle @channel={{@channel}} />
            </div>
          </LinkTo>
        {{else}}
          <div
            role="button"
            {{on "click" @drawerActions.toggleExpand}}
            class="chat-drawer-header__title"
          >
            <div class="chat-drawer-header__top-line">
              <ChannelTitle @channel={{@channel}}>
                {{#if @channel.tracking.unreadCount}}
                  <span class="chat-unread-count">
                    {{@channel.tracking.unreadCount}}
                  </span>
                {{/if}}
              </ChannelTitle>
            </div>
          </div>
        {{/if}}
      {{/if}}
    
  */
  {
    "id": "XH9Zj+dU",
    "block": "[[[1,\"\\n\"],[41,[30,1],[[[41,[30,0,[\"chatStateManager\",\"isDrawerExpanded\"]],[[[1,\"        \"],[8,[32,0],[[24,0,\"chat-drawer-header__title\"]],[[\"@route\",\"@models\"],[[52,[30,1,[\"isDirectMessageChannel\"]],\"chat.channel.info.settings\",\"chat.channel.info.members\"],[30,1,[\"routeModels\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[10,0],[14,0,\"chat-drawer-header__top-line\"],[12],[1,\"\\n            \"],[8,[32,1],null,[[\"@channel\"],[[30,1]]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[11,0],[24,\"role\",\"button\"],[24,0,\"chat-drawer-header__title\"],[4,[32,2],[\"click\",[30,2,[\"toggleExpand\"]]],null],[12],[1,\"\\n          \"],[10,0],[14,0,\"chat-drawer-header__top-line\"],[12],[1,\"\\n            \"],[8,[32,1],null,[[\"@channel\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,1,[\"tracking\",\"unreadCount\"]],[[[1,\"                \"],[10,1],[14,0,\"chat-unread-count\"],[12],[1,\"\\n                  \"],[1,[30,1,[\"tracking\",\"unreadCount\"]]],[1,\"\\n                \"],[13],[1,\"\\n\"]],[]],null],[1,\"            \"]],[]]]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]]],[]],null],[1,\"  \"]],[\"@channel\",\"@drawerActions\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-drawer/header/channel-title.js",
    "scope": () => [_routing.LinkTo, _channelTitle.default, _modifier.on],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatStateManager", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});