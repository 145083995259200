define("discourse/plugins/chat/discourse/components/chat/thread/header", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/routing", "@ember/service", "discourse/components/d-button", "discourse/helpers/concat-class", "discourse/helpers/replace-emoji", "discourse/lib/ajax-error", "discourse/lib/notification-levels", "discourse-common/helpers/d-icon", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat/modal/thread-settings", "discourse/plugins/chat/discourse/components/chat/thread/header-unread-indicator", "discourse/plugins/chat/discourse/models/user-chat-thread-membership", "discourse/plugins/chat/select-kit/addons/components/thread-notifications-button", "@ember/template-factory", "@ember/component"], function (_exports, _component, _tracking, _object, _routing, _service, _dButton, _concatClass, _replaceEmoji, _ajaxError, _notificationLevels, _dIcon, _discourseI18n, _threadSettings, _headerUnreadIndicator, _userChatThreadMembership, _threadNotificationsButton, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatThreadHeader = _exports.default = (_class = (_class2 = class ChatThreadHeader extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "chatApi", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _initializerDefineProperty(this, "chatStateManager", _descriptor4, this);
      _initializerDefineProperty(this, "chatHistory", _descriptor5, this);
      _initializerDefineProperty(this, "site", _descriptor6, this);
      _initializerDefineProperty(this, "modal", _descriptor7, this);
      _initializerDefineProperty(this, "persistedNotificationLevel", _descriptor8, this);
      _defineProperty(this, "closeThreadTitle", _discourseI18n.default.t("chat.thread.close"));
    }
    get backLink() {
      const prevPage1 = this.chatHistory.previousRoute?.name;
      let route1, title1, models1;
      if (prevPage1 === "chat.channel.threads") {
        route1 = "chat.channel.threads";
        title1 = _discourseI18n.default.t("chat.return_to_threads_list");
        models1 = this.args.channel.routeModels;
      } else if (prevPage1 === "chat.channel.index" && !this.site.mobileView) {
        route1 = "chat.channel.threads";
        title1 = _discourseI18n.default.t("chat.return_to_threads_list");
        models1 = this.args.channel.routeModels;
      } else if (!this.currentUser.isInDoNotDisturb() && this.unreadCount > 0) {
        route1 = "chat.channel.threads";
        title1 = _discourseI18n.default.t("chat.return_to_threads_list");
        models1 = this.args.channel.routeModels;
      } else if (prevPage1 === "chat.threads") {
        route1 = "chat.threads";
        title1 = _discourseI18n.default.t("chat.my_threads.title");
        models1 = [];
      } else {
        route1 = "chat.channel.index";
        title1 = _discourseI18n.default.t("chat.return_to_channel");
        models1 = this.args.channel.routeModels;
      }
      return {
        route: route1,
        models: models1,
        title: title1
      };
    }
    get canChangeThreadSettings() {
      if (!this.args.thread) {
        return false;
      }
      return this.currentUser.staff || this.currentUser.id === this.args.thread.originalMessage.user.id;
    }
    get threadNotificationLevel() {
      return this.membership?.notificationLevel || _notificationLevels.NotificationLevels.REGULAR;
    }
    get membership() {
      return this.args.thread.currentUserMembership;
    }
    get headerTitle() {
      return this.args.thread?.title ?? _discourseI18n.default.t("chat.thread.label");
    }
    get unreadCount() {
      return this.args.channel.threadsManager.unreadThreadCount;
    }
    openThreadSettings() {
      this.modal.show(_threadSettings.default, {
        model: this.args.thread
      });
    }
    updateThreadNotificationLevel(newNotificationLevel1) {
      this.persistedNotificationLevel = false;
      let currentNotificationLevel1;
      if (this.membership) {
        currentNotificationLevel1 = this.membership.notificationLevel;
        this.membership.notificationLevel = newNotificationLevel1;
      } else {
        this.args.thread.currentUserMembership = _userChatThreadMembership.default.create({
          notification_level: newNotificationLevel1,
          last_read_message_id: null
        });
      }
      return this.chatApi.updateCurrentUserThreadNotificationsSettings(this.args.thread.channel.id, this.args.thread.id, {
        notificationLevel: newNotificationLevel1
      }).then(response1 => {
        this.membership.last_read_message_id = response1.membership.last_read_message_id;
        this.persistedNotificationLevel = true;
      }).catch(err1 => {
        this.membership.notificationLevel = currentNotificationLevel1;
        (0, _ajaxError.popupAjaxError)(err1);
      });
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="chat-thread-header">
        <div class="chat-thread-header__left-buttons">
          {{#if @thread}}
            <LinkTo
              class="chat-thread__back-to-previous-route btn-flat btn btn-icon no-text"
              @route={{this.backLink.route}}
              @models={{this.backLink.models}}
              title={{this.backLink.title}}
            >
              <ChatThreadHeaderUnreadIndicator @channel={{@thread.channel}} />
              {{icon "chevron-left"}}
            </LinkTo>
          {{/if}}
        </div>
  
        <span class="chat-thread-header__label overflow-ellipsis">
          {{replaceEmoji this.headerTitle}}
        </span>
  
        <div
          class={{concatClass
            "chat-thread-header__buttons"
            (if this.persistedNotificationLevel "-persisted")
          }}
        >
          <ThreadNotificationsButton
            @value={{this.threadNotificationLevel}}
            @onChange={{this.updateThreadNotificationLevel}}
          />
          {{#if this.canChangeThreadSettings}}
            <DButton
              @action={{this.openThreadSettings}}
              @icon="cog"
              @title="chat.thread.settings"
              class="btn-flat chat-thread-header__settings"
            />
          {{/if}}
          {{#unless this.site.mobileView}}
            <LinkTo
              class="chat-thread__close btn-flat btn btn-icon no-text"
              @route="chat.channel"
              @models={{@thread.channel.routeModels}}
              title={{this.closeThreadTitle}}
            >
              {{icon "times"}}
            </LinkTo>
          {{/unless}}
        </div>
      </div>
    
  */
  {
    "id": "JqzFWoS5",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-thread-header\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"chat-thread-header__left-buttons\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"          \"],[8,[32,0],[[24,0,\"chat-thread__back-to-previous-route btn-flat btn btn-icon no-text\"],[16,\"title\",[30,0,[\"backLink\",\"title\"]]]],[[\"@route\",\"@models\"],[[30,0,[\"backLink\",\"route\"]],[30,0,[\"backLink\",\"models\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[32,1],null,[[\"@channel\"],[[30,1,[\"channel\"]]]],null],[1,\"\\n            \"],[1,[28,[32,2],[\"chevron-left\"],null]],[1,\"\\n          \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\\n      \"],[10,1],[14,0,\"chat-thread-header__label overflow-ellipsis\"],[12],[1,\"\\n        \"],[1,[28,[32,3],[[30,0,[\"headerTitle\"]]],null]],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[15,0,[28,[32,4],[\"chat-thread-header__buttons\",[52,[30,0,[\"persistedNotificationLevel\"]],\"-persisted\"]],null]],[12],[1,\"\\n        \"],[8,[32,5],null,[[\"@value\",\"@onChange\"],[[30,0,[\"threadNotificationLevel\"]],[30,0,[\"updateThreadNotificationLevel\"]]]],null],[1,\"\\n\"],[41,[30,0,[\"canChangeThreadSettings\"]],[[[1,\"          \"],[8,[32,6],[[24,0,\"btn-flat chat-thread-header__settings\"]],[[\"@action\",\"@icon\",\"@title\"],[[30,0,[\"openThreadSettings\"]],\"cog\",\"chat.thread.settings\"]],null],[1,\"\\n\"]],[]],null],[41,[51,[30,0,[\"site\",\"mobileView\"]]],[[[1,\"          \"],[8,[32,0],[[24,0,\"chat-thread__close btn-flat btn btn-icon no-text\"],[16,\"title\",[30,0,[\"closeThreadTitle\"]]]],[[\"@route\",\"@models\"],[\"chat.channel\",[30,1,[\"channel\",\"routeModels\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[32,2],[\"times\"],null]],[1,\"\\n          \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@thread\"],false,[\"if\",\"unless\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/thread/header.js",
    "scope": () => [_routing.LinkTo, _headerUnreadIndicator.default, _dIcon.default, _replaceEmoji.default, _concatClass.default, _threadNotificationsButton.default, _dButton.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chatApi", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "chatStateManager", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "chatHistory", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "persistedNotificationLevel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "openThreadSettings", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openThreadSettings"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateThreadNotificationLevel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateThreadNotificationLevel"), _class.prototype)), _class);
});